import React from 'react';
import { RulesChartCard } from '@kw/quokka-ui';
import _ from 'lodash';

export const RulesChart = ({ rulesSummary = [] }) => {
  const getNumberOfRulesBasedOnRiskLevelSummary = (results, level) =>
    parseInt(
      _.find(results, item => item.level?.toLowerCase() === level.toLowerCase())
        ?.resultsCount,
      10,
    ) || 0;

  const noteCount = getNumberOfRulesBasedOnRiskLevelSummary(
    rulesSummary,
    'note',
  );
  const warningCount = getNumberOfRulesBasedOnRiskLevelSummary(
    rulesSummary,
    'warning',
  );
  const errorCount = getNumberOfRulesBasedOnRiskLevelSummary(
    rulesSummary,
    'error',
  );

  return (
    <RulesChartCard
      error={errorCount}
      note={noteCount}
      warning={warningCount}
    />
  );
};
