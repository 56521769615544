import React from 'react';
import { RulesChartCard } from '@kw/quokka-ui';

export const BehaviorsChartCard = ({ results }) => {
  const getNumberOfIorsBasedOnRiskLevel = (results, level) =>
    results.reduce((acc, item) => (item.risk === level ? acc + 1 : acc), 0);

  const numberOfNoteIors = getNumberOfIorsBasedOnRiskLevel(results, 'note');

  const numberOfWarningIors = getNumberOfIorsBasedOnRiskLevel(
    results,
    'warning',
  );

  const numberOfErrorIors = getNumberOfIorsBasedOnRiskLevel(results, 'error');

  return (
    <RulesChartCard
      error={numberOfErrorIors}
      note={numberOfNoteIors}
      warning={numberOfWarningIors}
      title="Behaviors"
    />
  );
};
