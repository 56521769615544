import React from 'react';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QDropdownSelect } from './Q-Components/QDropdownSelect';
import { QSelect } from './Q-Components/QSelect';

export const FilterSelect = ({
  selectedValue,
  onChange,
  label,
  filteredKey,
  originalData,
  customOptions = [],
}) => {
  const { mastV2 } = useFlags();

  const generateOptions = () => {
    if (customOptions.length) {
      return customOptions;
    }

    return _.uniqBy(
      originalData.map(item => {
        const value = _.get(item, filteredKey);
        const label =
          typeof value === 'string' ? _.capitalize(value) : 'Not specified';

        return {
          label,
          value,
        };
      }),
      'label',
    );
  };

  const options = [{ label: 'All', value: 'all' }, ...generateOptions()];

  const handleSelectChange = event => {
    if (!mastV2) {
      return onChange(event);
    }

    const selected = event.target.value;
    // Find the selected option based on value
    const option = options.find(opt => opt.value === selected) || {
      label: 'All',
      value: 'all',
    };
    return onChange(option);
  };

  return (
    <div style={{ width: '200px' }}>
      {mastV2 ? (
        <QSelect
          size="small"
          options={options}
          value={selectedValue.value}
          onChange={handleSelectChange}
          label={label}
          formControlProps={{ sx: { width: '180px' } }}
          selectIconProps={{ fontSize: 'medium' }}
          selectProps={{
            'data-testid': 'select-filter',
            sx: { backgroundColor: '#FFFFFF' },
          }}
        />
      ) : (
        <QDropdownSelect
          data-testid="select-filter-v1"
          className="mr-2"
          id={`${filteredKey}-select`}
          placeholder={label}
          options={options}
          onChange={handleSelectChange}
        />
      )}
    </div>
  );
};
