import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { toSnake } from 'snake-camel';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { DateTime } from '../DateTime';
import AnalyzedAppInfo from '../AnalyzedApps/AnalyzedAppInfo';
import { QBadge } from '../Q-Components/QBadge';
import { QUncontrolledTooltip } from '../Q-Components/QUncontrolledTooltip';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableCell } from '../Q-Components/QTableCell';
import { QChip } from '../Q-Components/QChip';

import { WatchlistAppActions } from './WatchlistAppActions';

class WatchlistApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      app: this.props.app,
      groups: [],
      ownerGroups: [],
      ownerGroupsNames: '',
      ownerGroupsNamesExtended: '',
    };
    this.setSubgroupOwnerList = this.setSubgroupOwnerList.bind(this);
    this.setSubgroupNamesList = this.setSubgroupNamesList.bind(this);
  }

  async componentDidMount() {
    // Get all subgroups the user is a member of
    const groupsBuffer = [];
    this.props.userSubGroups.forEach(entry => {
      groupsBuffer.push({
        id: entry.id,
        name: entry.name,
      });
    });
    this.setState({ groups: groupsBuffer }, this.setSubgroupOwnerList);
  }

  // Set Subgroup ownership options for the watchlist app
  setSubgroupOwnerList = () => {
    const { app, groups } = this.state;
    const { associatedSubGroupIds } = app;
    if (associatedSubGroupIds) {
      const ownerGroupsBuffer = [];
      associatedSubGroupIds.forEach(subGroupId => {
        if (groups.findIndex(group => group.id === subGroupId) >= 0) {
          ownerGroupsBuffer.push({
            value: subGroupId,
            label:
              groups[groups.findIndex(group => group.id === subGroupId)].name,
          });
        }
      });
      this.setState(
        { ownerGroups: ownerGroupsBuffer },
        this.setSubgroupNamesList,
      );
    }
  };

  // Set Subgroup names list
  setSubgroupNamesList = () => {
    let ownerGroupsNamesBuffer = '';
    let ownerGroupsNamesExtendedBuffer = '';
    let groupCount = 0;

    this.state.ownerGroups.forEach(ownerGroup => {
      if (groupCount < 5) {
        ownerGroupsNamesBuffer = `${
          ownerGroupsNamesBuffer + ownerGroup.label
        },`;
      } else {
        ownerGroupsNamesExtendedBuffer = `${
          ownerGroupsNamesExtendedBuffer + ownerGroup.label
        },`;
      }
      groupCount++;
    });
    if (ownerGroupsNamesExtendedBuffer.length === 0) {
      ownerGroupsNamesBuffer = ownerGroupsNamesBuffer.substring(
        0,
        ownerGroupsNamesBuffer.length - 1,
      );
    }
    ownerGroupsNamesExtendedBuffer = ownerGroupsNamesExtendedBuffer.substring(
      0,
      ownerGroupsNamesExtendedBuffer.length - 1,
    );
    this.setState({ ownerGroupsNames: ownerGroupsNamesBuffer });
    this.setState({ ownerGroupsNamesExtended: ownerGroupsNamesExtendedBuffer });
  };

  render() {
    const { app, ownerGroupsNames, ownerGroupsNamesExtended } = this.state;
    const { checkVersion, updateExternalId, deleteWatchlistApp, searchApp } =
      this.props;

    const analyzedApp = toSnake(app).analyzed_app;

    return (
      analyzedApp && (
        <QTableRow>
          <QTableCell width="20%">
            <div className={app.stillAvailable ? '' : 'text-disabled'}>
              {analyzedApp && (
                /* App name, package and icon */
                <AnalyzedAppInfo app={analyzedApp} />
              )}
            </div>
            {!app.stillAvailable && (
              <QBadge
                color="danger"
                variant="red"
                label="App is no longer available."
                pill
              >
                App is no longer available.
              </QBadge>
            )}
          </QTableCell>

          {/* Latest version */}
          <QTableCell width="20%">
            {app.analyzedApp?.farmApp?.version}
          </QTableCell>

          {/* Latest submission */}
          <QTableCell width="10%">
            <DateTime dateTime={moment(app.lastSubmittedAt).format('L LTS')} />
          </QTableCell>

          {/* Tracking since */}
          <QTableCell width="10%" className="text-center">
            <DateTime dateTime={moment(app.trackingSince).format('L LTS')} />
          </QTableCell>

          {/* Number of versions analyzed for watchlist app */}
          <QTableCell width="5%" className="text-center">
            {app.versionsAnalyzed}
          </QTableCell>

          {/* Subgroups that the app will be assigned to */}
          <QTableCell width="25%" className="text-center">
            {ownerGroupsNames}{' '}
            {ownerGroupsNamesExtended.length > 0 && (
              <>
                {!this.props.flags.mastV2 && (
                  <QChip
                    id="groups-extended-list"
                    label="..."
                    pill
                    variant="basic"
                  >
                    ...
                  </QChip>
                )}

                <QUncontrolledTooltip
                  placement="bottom"
                  target="groups-extended-list"
                  title={ownerGroupsNamesExtended}
                >
                  {this.props.flags.mastV2 && (
                    <div style={{ width: 'fit-content' }}>
                      <QChip
                        id="groups-extended-list"
                        label="..."
                        pill
                        variant="basic"
                      >
                        ...
                      </QChip>
                    </div>
                  )}
                </QUncontrolledTooltip>
              </>
            )}
          </QTableCell>

          {/* Actions */}
          <QTableCell width="10%" className="text-center">
            <WatchlistAppActions
              app={app}
              searchApp={searchApp}
              checkVersion={checkVersion}
              updateExternalId={updateExternalId}
              deleteWatchlistApp={deleteWatchlistApp}
            />
          </QTableCell>
        </QTableRow>
      )
    );
  }
}

const mapStateToProps = state => ({
  userAccess: state.emmApp.userAccess,
  userConfig: state.emmApp.userConfig,
  userSubGroups: state.emmApp.userSubGroups,
});

const WatchlistAppWithLDConsumer = withLDConsumer()(WatchlistApp);

export default connect(mapStateToProps)(WatchlistAppWithLDConsumer);
