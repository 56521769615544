import axios from 'axios';

import { filterByNestedProperty } from '../../SharedFilterSelects';

export const getRulesFiltersConfig = (ruleSummaries, excludeConfig = []) =>
  [
    {
      key: 'riskLevel',
      label: 'Risk Level',
      filteredKey: 'details.risk',
      originalData: ruleSummaries,
    },
    {
      key: 'category',
      label: 'Category',
      filteredKey: 'details.category',
      originalData: ruleSummaries,
    },
    {
      key: 'compliance',
      label: 'Compliance',
      filteredKey: 'rule.properties.compliance',
      customHandleFilterChange: (filteredKey, data, option) => {
        let optionValues;

        if (option.value === 'owasp') {
          optionValues = ['owasp-2016', 'owasp-2024'];
        } else {
          optionValues = option.value;
        }

        return filterByNestedProperty({
          data,
          filteredKey,
          optionValues,
          predicate: item => item.fail || item.kind === 'fail',
        });
      },
      customOptions: [
        { label: 'OWASP', value: 'owasp' },
        { label: 'NIAP', value: 'niap' },
        { label: 'GDPR', value: 'gdpr' },
      ],
      originalData: ruleSummaries,
    },
  ].filter(config => !excludeConfig.includes(config.key));

export const getEvidencesCallback = async (uuid, ruleGuid, page, pageSize) =>
  axios.get(
    `/analysis-results/evidence?uuid=${uuid}&ruleGuid=${ruleGuid}&page=${page}&pageSize=${pageSize}`,
  );
