import React, { useState } from 'react';
import { HeaderAndMetadata } from '@kw/quokka-ui';

import { MetadataPanel } from './MetadataPanel';

export const GuardianReportHeaderAndMetadata = ({
  app,
  threatScore,
  metadata,
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  return (
    <>
      <HeaderAndMetadata
        appName={app.farm_app?.name}
        packageName={app.farm_app?.package}
        platform={app.farm_app?.platform}
        version={app.farm_app?.version}
        externalId={app.external_id}
        testScriptName={app.farm_app?.script_name}
        submissionTag={app.submissionTag}
        submittedAt={app.farm_app?.submitted_at}
        score={threatScore}
        appUrl={app.farm_app?.store_url || app.farm_app?.storeUrl}
        appIconUrls={[
          app.farm_app?.icon_large_url,
          app.farm_app?.iconLargeUrl,
          app.farm_app?.icon_base64
            ? `data:image/png;base64,${app.farm_app?.icon_base64}`
            : null,
          app.farm_app?.iconBase64
            ? `data:image/png;base64,${app.farm_app?.iconBase64}`
            : null,
          app.farm_app?.icon_small_url,
          app.farm_app?.iconSmallUrl,
        ].filter(Boolean)}
        viewMetadataOnClick={() => setIsPanelOpen(true)}
      />
      <MetadataPanel
        isOpen={isPanelOpen}
        setIsOpen={setIsPanelOpen}
        app={app}
        metadata={metadata}
      />
    </>
  );
};
