import React from 'react';
import { AppMetadataPanel } from '@kw/quokka-ui';
import _ from 'lodash';
import fileSize from 'filesize';

export const MetadataPanel = ({ isOpen, setIsOpen, app, metadata }) => {
  const { submitted_at: submittedAt, submissionTag } = app || {};

  const appInfo = {
    name: metadata.name,
    version: metadata.version,
    versionCode: metadata.versionCode,
    minSdk: metadata.minOsVersion,
    targetSdk: metadata.targetSdk,
    fileSize: metadata.size ? fileSize(metadata.size) : '',
    submissionDate: submittedAt || metadata.submitted_at,
    submissionTag,
    sha1: metadata.sha1,
    md5: metadata.hash,
  };

  const marketInfo = {
    category:
      metadata.categories?.map(category => _.capitalize(category)).join(', ') ||
      '',
    lastUpdated: metadata.releaseDate,
    download: metadata.downloads,
    privacyPolicy: metadata.privacyPolicyUrl,
    developer: metadata.developerName,
    developerEmail: metadata.developerEmail,
    developerUrl: metadata.developerUrl,
  };

  return (
    <AppMetadataPanel
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      appInfo={appInfo}
      marketInfo={marketInfo}
      certificateInfo={{}}
    />
  );
};
