import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QTable } from '../../../../Q-Components/QTable';
import { QTableBody } from '../../../../Q-Components/QTableBody';
import { QTypography } from '../../../../Q-Components/QTypography';
import AsyncTablePagination from '../../../../AsyncTablePagination';
import Loading from '../../../../Loading';
import { toastrHelper } from '../../../../../logic/toastrHelper';

import { LocationEvidence } from './LocationEvidence';

export function NewReportEvidence({ description, ruleGuid, uuid, isExpanded }) {
  const { mastV2 } = useFlags();

  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState({ value: 10, label: '10' });
  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];
  const [pagination, setPagination] = useState({
    total: 0,
    lastPage: 0,
    perPage: 0,
  });
  const [evidence, setEvidence] = useState([]);

  const fetchEvidence = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/analysis-results/evidence?uuid=${uuid}&ruleGuid=${ruleGuid}&page=${page}&pageSize=${pageSize.value}`,
      );
      const { entries, pagination } = data;
      const evidence = entries.map(evidence => evidence.result);

      setEvidence(evidence);
      setPagination(pagination);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toastrHelper.showErrorToast(err, 'Error', mastV2);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      fetchEvidence();
    }
  }, [page, pageSize, description, isExpanded]);

  const handleChangePage = (newPage, event) => {
    event.preventDefault();
    setPage(newPage);
  };

  // Handle page size change
  const handlePageSizeChange = option => {
    setPageSize(option);
    setPage(1);
  };

  return (
    <>
      {pagination.total > pageSizeOptions[0].value && (
        <AsyncTablePagination
          pagination={{
            lastPage: pagination.lastPage,
            totalData: pagination.total,
            dataPerPage: pageSize.value,
          }}
          getPage={handleChangePage}
          page={page}
          showRowsPerPage
          currentRows={evidence.length}
          handlePageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <QTable>
          <QTableBody>
            {pagination.total > 0 &&
              evidence.map(evidence => (
                <>
                  {evidence.message?.text &&
                    evidence.message.text !== description && (
                      <div style={{ marginTop: '10px' }}>
                        <b>
                          <QTypography variant="bodyRegular">
                            {evidence.message.text}
                          </QTypography>
                        </b>
                      </div>
                    )}

                  {evidence?.locations.map(location => (
                    <LocationEvidence
                      key={location.id}
                      location={location}
                      codeFlows={evidence.codeFlows}
                      stacks={evidence.stacks}
                      evidences={evidence}
                    />
                  ))}
                </>
              ))}
          </QTableBody>
        </QTable>
      )}

      {pagination.total > pageSizeOptions[0].value && (
        <div style={{ marginTop: '10px' }}>
          <AsyncTablePagination
            pagination={{
              lastPage: pagination.lastPage,
              totalData: pagination.total,
              dataPerPage: pageSize.value,
            }}
            getPage={handleChangePage}
            page={page}
            showRowsPerPage
            currentRows={evidence.length}
            handlePageSizeChange={handlePageSizeChange}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
          />
        </div>
      )}
    </>
  );
}
