import React, { useEffect, useState, forwardRef } from 'react';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from 'react-datepicker';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ConfirmationModal from '../../Modals/ConfirmationModal';
import { toastrHelper } from '../../../logic/toastrHelper';
import { QModal } from '../../Q-Components/QModal';
import { QTypography } from '../../Q-Components/QTypography';
import { QLabel } from '../../Q-Components/QLabel';
import { QModalContent } from '../../Q-Components/QModalContent';
import { QModalTitle } from '../../Q-Components/QModalTitle';
import { QModalActions } from '../../Q-Components/QModalActions';
import { QButton } from '../../Q-Components/QButton';
import { QFormGroup } from '../../Q-Components/QFormGroup';
import { QInput } from '../../Q-Components/QInput';
import { QRow } from '../../Q-Components/QRow';
import { QSelect } from '../../Q-Components/QSelect';
import { QForm } from '../../Q-Components/QForm';
import { QCol } from '../../Q-Components/QCol';

import { LicenseType } from './LicenseType';

const LicenseForm = ({
  methods,
  licenseTypeData,
  groupsData,
  type,
  group,
  licenseDetails,
  data,
  appScans,
}) => {
  const { mastV2 } = useFlags();
  const { register, control, errors, watch } = methods;
  const watchLicenseType = watch('licenseType');

  const license =
    type === 'UPDATE'
      ? { label: data?.licenseType?.name, value: data?.licenseType?.id }
      : null;

  const maxScans = type === 'UPDATE' ? data?.maxScans : 10;

  const maxUniqueApps = type === 'UPDATE' ? data?.maxUniqueApps : 10;

  const numOfTopApps = type === 'UPDATE' ? data?.numOfTopApps : 0;

  const maxGroupUsers = type === 'UPDATE' ? data?.maxGroupUsers : 1;

  const startDate =
    type === 'UPDATE'
      ? moment(data?.startDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');

  const endDate =
    type === 'UPDATE'
      ? moment(data?.endDate).format('YYYY-MM-DD')
      : moment().add(30, 'days').format('YYYY-MM-DD');

  const StartDateInput = forwardRef((props, ref) => (
    <QInput
      {...props}
      inputRef={ref}
      placeholder="Start Date"
      style={{ backgroundColor: 'white', width: mastV2 ? '495px' : '468px' }}
      inputProps={{ 'data-testid': 'test-start-date' }}
      fullWidth
    />
  ));

  const EndDateInput = forwardRef((props, ref) => (
    <QInput
      {...props}
      inputRef={ref}
      placeholder="End Date"
      style={{ backgroundColor: 'white', width: mastV2 ? '495px' : '468px' }}
      inputProps={{ 'data-testid': 'test-end-date' }}
      fullWidth
    />
  ));

  const isOnDemandLicense =
    watchLicenseType && watchLicenseType.value === LicenseType.ONDEMAND;

  const registrationOptions = {
    licenseType: {
      required: 'Please select a license type',
    },

    licenseGroup: {
      required: 'Please select a group',
    },

    maxUniqueApps: {
      validate: value => {
        if (!value && !isOnDemandLicense) return 'Value is required';
        return true;
      },
      min: {
        value: 0,
        message: 'Value must greater than 0',
      },
    },

    maxGroupUsers: {
      required: 'Value is required',
      min: {
        value: 1,
        message: 'Value must greater than 0',
      },
    },

    maxScans: {
      validate: value => {
        if (!value && isOnDemandLicense) return 'Value is required';
        return true;
      },
      min: {
        value: 0,
        message: 'Value must greater than 0',
      },
    },

    numOfTopApps: {
      required: false,
      min: {
        value: 0,
        message: 'Value must greater than 0',
      },
    },

    licenseStartDate: {
      required: 'Please select start date',
      validate: {
        beforeStartDate: value =>
          moment(value).isBefore(endDate) ||
          'Start date must be before end date',
      },
    },

    licenseEndDate: {
      required: 'Please select end date',
      validate: {
        afterStartDate: value =>
          moment(value).isAfter(startDate) ||
          'End date must be after start date',
      },
    },
  };

  const handleChangeLicenseType = (event, newLicenseType, onChange) => {
    if (mastV2) {
      const newOption = licenseTypeData.find(
        option => option.value === newLicenseType.props.value,
      );

      onChange(newOption);
    } else {
      onChange(event);
    }
  };

  const handleChangeLicenseGroup = (event, newLicenseGroup, onChange) => {
    if (mastV2) {
      const newOption = groupsData.find(
        option => option.value === newLicenseGroup.props.value,
      );

      onChange(newOption);
    } else {
      onChange(event);
    }
  };

  const getRef = field => {
    const reg = register(registrationOptions[field]);
    return mastV2 ? { inputRef: reg } : { innerRef: reg };
  };

  const getAttribute = {
    licenseType: {
      label: 'License Type',
      value: license ?? licenseTypeData[0],
      rules: registrationOptions.licenseType,
      onChange: handleChangeLicenseType,
      options: licenseTypeData,
    },
    licenseGroup: {
      label: 'Group',
      value: group ?? '',
      rules: registrationOptions.licenseGroup,
      onChange: handleChangeLicenseGroup,
      options: groupsData,
      isDisabled: type !== 'CREATE',
    },
    maxGroupUsers: {
      label: 'Max Group Users',
      value: maxGroupUsers,
      placeholder: 'Max number of users per license',
    },
    numOfTopApps: {
      label: 'Number of Top Apps (Child Instance)',
      value: numOfTopApps,
      placeholder: 'Max number of top apps per license',
    },
    licenseStartDate: {
      label: 'Start Date',
      value: moment(startDate).toDate(),
      rules: registrationOptions.licenseStartDate,
      customInput: <StartDateInput />,
    },
    licenseEndDate: {
      label: 'End Date',
      value: moment(endDate).toDate(),
      rules: registrationOptions.licenseEndDate,
      customInput: <EndDateInput />,
    },
    ...(isOnDemandLicense
      ? {
          maxScans: {
            label: 'Max Number of Scans',
            placeholder: 'Max number of scans per license',
            value: maxScans,
          },
        }
      : {
          maxUniqueApps: {
            label: 'Max Unique Apps',
            placeholder: 'Max unique apps per license',
            value: maxUniqueApps,
          },
        }),
  };

  return (
    <QModalContent sx={{ padding: '16px 29px 29px 16px' }}>
      <FormProvider {...methods}>
        <QForm>
          {['licenseType', 'licenseGroup'].map(field => (
            <QFormGroup key={field}>
              <QCol>
                <QRow
                  sx={{
                    width: '100%',
                    mt: 1,
                  }}
                >
                  <QLabel htmlFor={field}>
                    <QTypography variant="h5Medium">
                      {getAttribute[field].label}
                    </QTypography>
                  </QLabel>
                </QRow>
                <QRow sx={{ width: '100%', mt: 1 }}>
                  <Controller
                    name={field}
                    id={field}
                    control={control}
                    render={({ onChange, onBlur, value, ref }) => (
                      <div style={{ width: '100%', margin: '0 auto' }}>
                        <QSelect
                          onChange={(event, newLicenseType) =>
                            getAttribute[field].onChange(
                              event,
                              newLicenseType,
                              onChange,
                            )
                          }
                          value={!mastV2 ? value : value.value}
                          options={getAttribute[field].options}
                          formControlProps={{ sx: { width: '100%' } }}
                          onBlur={onBlur}
                          selectProps={{
                            sx: { backgroundColor: 'white' },
                            ref,
                            onBlur,
                            disabled: getAttribute[field].isDisabled,
                          }}
                          isDisabled={getAttribute[field].isDisabled}
                        />
                      </div>
                    )}
                    rules={getAttribute[field].rules}
                    defaultValue={getAttribute[field].value}
                  />
                </QRow>
                <QRow
                  sx={{
                    width: '100%',
                    mt: 1,
                  }}
                >
                  <QTypography>
                    <small className="text-danger">
                      {errors[field] && errors[field].message}
                    </small>
                  </QTypography>
                </QRow>
              </QCol>
            </QFormGroup>
          ))}

          {[
            ...(isOnDemandLicense ? ['maxScans'] : ['maxUniqueApps']),
            'maxGroupUsers',
            'numOfTopApps',
          ].map(field => (
            <QFormGroup key={field}>
              <QCol>
                <QRow
                  sx={{
                    width: '100%',
                    mt: 1,
                  }}
                >
                  <QLabel htmlFor={field}>
                    <QTypography variant="h5Medium">
                      {getAttribute[field].label}
                    </QTypography>
                  </QLabel>
                </QRow>
                <QRow sx={{ width: '100%', mt: 1 }}>
                  <QInput
                    name={field}
                    id={field}
                    type="number"
                    placeholder={getAttribute[field].placeholder}
                    {...getRef(field)}
                    fullWidth
                    defaultValue={getAttribute[field].value}
                    sx={{ backgroundColor: 'white' }}
                  />
                </QRow>
                <QRow
                  sx={{
                    width: '100%',
                    mt: 1,
                  }}
                >
                  <QTypography>
                    <small className="text-danger">
                      {errors[field] && errors[field].message}
                    </small>
                  </QTypography>
                </QRow>
              </QCol>
            </QFormGroup>
          ))}
          {['licenseStartDate', 'licenseEndDate'].map(field => (
            <QFormGroup key={field}>
              <QCol>
                <QRow
                  sx={{
                    width: '100%',
                    mt: 1,
                  }}
                >
                  <QLabel htmlFor={field}>
                    <QTypography variant="h5Medium">
                      {getAttribute[field].label}
                    </QTypography>
                  </QLabel>
                </QRow>
                <QRow sx={{ width: '100%', mt: 1 }}>
                  <Controller
                    name={field}
                    id={field}
                    control={control}
                    rules={getAttribute[field].rules}
                    defaultValue={getAttribute[field].value}
                    render={({ value, onChange, onBlur }) => (
                      <DatePicker
                        selected={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        customInput={getAttribute[field].customInput}
                      />
                    )}
                  />
                </QRow>
                <QRow
                  sx={{
                    width: '100%',
                    mt: 1,
                  }}
                >
                  <QTypography>
                    <small className="text-danger">
                      {errors[field] && errors[field].message}
                    </small>
                  </QTypography>
                </QRow>
              </QCol>
            </QFormGroup>
          ))}

          {licenseDetails && type === 'UPDATE' && (
            <>
              <QCol>
                <QRow>
                  <QTypography>
                    <strong>Remaining Apps:</strong> {data.remainingApps}
                  </QTypography>
                </QRow>

                <QRow>
                  <QTypography>
                    <strong>Remaining Days:</strong>{' '}
                    {Math.ceil(data.remainingDays)}
                  </QTypography>
                </QRow>
              </QCol>
              {!isOnDemandLicense ? (
                <Pie
                  width={250}
                  height={250}
                  options={{
                    maintainAspectRatio: false,
                    responsive: false,
                  }}
                  data={{
                    datasets: [
                      {
                        data: [
                          licenseDetails.uniqueApps,
                          Math.max(
                            licenseDetails.licenseApps -
                              licenseDetails.uniqueApps,
                            0,
                          ),
                        ],
                        backgroundColor: ['#ffa000', '#388e3c'],
                      },
                    ],
                    labels: ['Apps Analyzed', 'Apps Remaining'],
                  }}
                />
              ) : (
                <Pie
                  width={250}
                  height={250}
                  options={{
                    maintainAspectRatio: false,
                    responsive: false,
                  }}
                  data={{
                    datasets: [
                      {
                        data: [appScans, Math.max(maxScans - appScans, 0)],
                        backgroundColor: ['#ffa000', '#388e3c'],
                      },
                    ],
                    labels: ['Scans Used', 'Scans Remaining'],
                  }}
                />
              )}
            </>
          )}
        </QForm>
      </FormProvider>
    </QModalContent>
  );
};

export function LicenseProfile({
  isOpen,
  toggle,
  type,
  data,
  groupsData,
  licenseTypeData,
  fetchData,
}) {
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [licenseDetails, setLicenseDetails] = useState(null);
  const [appScans, setAppScans] = useState(0);
  const { mastV2 } = useFlags();

  const methods = useForm({
    mode: 'onTouched',
  });

  const { formState, handleSubmit } = methods;

  const { isValid, isDirty } = formState;

  const group =
    type === 'UPDATE'
      ? { label: data?.userGroup.name, value: data?.userGroup.id }
      : null;

  useEffect(() => {
    const getLicenseDetails = async () => {
      try {
        const response = await axios.get(`adminV2/licenses/${data.id}`);

        setLicenseDetails(response.data);
      } catch (err) {
        toastrHelper.showErrorToast(
          'Error getting license details',
          'Error',
          mastV2,
        );
      }
    };
    const getAppScansDetails = async () => {
      try {
        const response = await axios.get(
          `adminV2/licenses/utilization/${data.id}`,
        );

        setAppScans(response.data.scans);
      } catch (err) {
        toastrHelper.showErrorToast(
          'Error getting license details',
          'Error',
          mastV2,
        );
      }
    };
    if (data) {
      getLicenseDetails();
      // For on-demand licenses we need to fetch the information of app scans
      if (data.licenseTypeId === LicenseType.ONDEMAND) {
        getAppScansDetails();
      }
    }
  }, [data]);

  const handleCreateOrUpdate = async ({
    maxUniqueApps,
    numOfTopApps,
    maxScans,
    licenseEndDate,
    licenseStartDate,
    licenseGroup,
    licenseType,
    maxGroupUsers,
  }) => {
    try {
      await axios.post('/adminV2/license', {
        groupId: licenseGroup.value,
        maxUniqueApps: Number(maxUniqueApps),
        numOfTopApps: Number(numOfTopApps),
        maxScans: Number(maxScans),
        startDate: licenseStartDate,
        endDate: licenseEndDate,
        licenseTypeId: licenseType.value ?? LicenseType.ENTERPRISE,
        maxGroupUsers: Number(maxGroupUsers),
      });

      toastrHelper.showSuccessToast(
        'Successfully saved license',
        'Success',
        mastV2,
      );
    } catch (err) {
      toastrHelper.showErrorToast('Error saving license', 'Error', mastV2);
    }

    toggle();
    fetchData();
  };

  const handleRemoveLicense = async groupId => {
    try {
      await axios.delete('/adminV2/license', {
        data: {
          groupId,
        },
      });
      toastrHelper.showSuccessToast(
        'Successfully removed license',
        'Success',
        mastV2,
      );
    } catch (err) {
      toastrHelper.showErrorToast('Error removing license', 'Error', mastV2);
    }

    toggle();
    fetchData();
  };

  const Title = () => (
    <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
      <QTypography variant="h3Medium">{type} License</QTypography>
    </QModalTitle>
  );

  const Footer = () => (
    <QModalActions>
      {type === 'UPDATE' && (
        <div className="d-flex justify-content-start">
          <QButton
            variant="outlined"
            color="danger"
            onClick={() => setRemoveConfirm(true)}
          >
            Remove License
          </QButton>
        </div>
      )}

      <QButton
        onClick={handleSubmit(handleCreateOrUpdate)}
        variant="filled"
        disabled={!isDirty || !isValid}
      >
        Save
      </QButton>
    </QModalActions>
  );

  return (
    <>
      <ConfirmationModal
        isOpen={removeConfirm}
        toggle={() => setRemoveConfirm(!removeConfirm)}
        confirmHeader="Remove License"
        confirmBody={`Are you sure to remove license for ${data?.userGroup.name}?`}
        confirm={() => {
          handleRemoveLicense(group.value);
          setRemoveConfirm(!removeConfirm);
        }}
      />
      <QModal
        isOpen={isOpen}
        open={isOpen}
        toggle={toggle}
        title={<Title />}
        content={
          <LicenseForm
            methods={methods}
            data={data}
            group={group}
            groupsData={groupsData}
            type={type}
            licenseDetails={licenseDetails}
            appScans={appScans}
            licenseTypeData={licenseTypeData}
          />
        }
        actions={<Footer />}
      >
        {!mastV2 && (
          <>
            <Title />
            <LicenseForm
              methods={methods}
              data={data}
              group={group}
              groupsData={groupsData}
              type={type}
              licenseDetails={licenseDetails}
              appScans={appScans}
              licenseTypeData={licenseTypeData}
            />
            <Footer />
          </>
        )}
      </QModal>
    </>
  );
}
