import React, { useState } from 'react';
import { RulesTable } from '@kw/quokka-ui';

import { SharedFilterSelects } from '../../SharedFilterSelects';
import { QTableRow } from '../../Q-Components/QTableRow';
import { QTable } from '../../Q-Components/QTable';
import { QTableBody } from '../../Q-Components/QTableBody';
import { QTableCell } from '../../Q-Components/QTableCell';

import { getEvidencesCallback, getRulesFiltersConfig } from './helper';

export const BehaviorsTab = ({ ruleSummaries, farmAppUuid }) => {
  const [filteredRuleSummaries, setFilteredRuleSummaries] = useState([]);

  const handleFilteredData = filteredData => {
    setFilteredRuleSummaries(filteredData);
  };

  const filtersConfig = getRulesFiltersConfig(ruleSummaries, 'category');

  return (
    <QTable data-testid="rules-table">
      <QTableBody>
        <QTableRow hoverDisabled>
          <QTableCell sx={{ padding: '0px' }}>
            <SharedFilterSelects
              filtersConfig={filtersConfig}
              originalData={ruleSummaries}
              onFilteredData={handleFilteredData}
            />
          </QTableCell>
        </QTableRow>
        <RulesTable
          ruleSummaries={filteredRuleSummaries}
          uuid={farmAppUuid}
          getEvidencesCallback={getEvidencesCallback}
          emptyTableText="No Behaviors Found"
        />
      </QTableBody>
    </QTable>
  );
};
