import { PermissionsChartCard } from '@kw/quokka-ui';
import _ from 'lodash';
import React from 'react';

export const PermissionsChart = ({ permissionsSummary = [] }) => {
  const getNumberOfPermissionsBasedOnTypeSummary = (results, type) =>
    parseInt(_.find(results, { ruleId: type })?.resultsCount, 10) || 0;

  const missingCount = getNumberOfPermissionsBasedOnTypeSummary(
    permissionsSummary,
    'missing-perm',
  );

  const unusedCount = getNumberOfPermissionsBasedOnTypeSummary(
    permissionsSummary,
    'unused-perm',
  );

  const unknownCount = getNumberOfPermissionsBasedOnTypeSummary(
    permissionsSummary,
    'unknown-perm',
  );

  const usedCount = getNumberOfPermissionsBasedOnTypeSummary(
    permissionsSummary,
    'used-perm',
  );
  return (
    <PermissionsChartCard
      missing={missingCount}
      unused={unusedCount}
      unknown={unknownCount}
      used={usedCount}
    />
  );
};
